import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query, SanityProductPageConnection } from "@graphql-types";
import { Components } from "@components";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import Hero from "@shared/hero";
import { ProviderContainer } from "@global";
import { useStore } from "@state/store";

interface Data extends Query {
  altRegions: SanityProductPageConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function ProductPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityProductPage;
  const { user } = useStore();

  const {
    password,
    components,
    hero,
    seo,
  } = nodes[0];
  const alternateRegions = data.altRegions.nodes.map(alt => alt.region);

  return (
    <div>
      <SEO seoData={seo} alternateRegions={alternateRegions} heroData={hero} slug={pageContext?.pagePath} />
      <ProviderContainer password={password} isAdmin={user?.isAdmin}>
        <Hero heroData={hero} isFullscreen />
        {components &&
          <Container flexDirection="column">
            {components.map(component => {
              if (component == null) return null;
              return (
                <Components key={component._key} data={component} />
              );
            })
            }
          </Container>
        }
      </ProviderContainer>
    </div>
  );
}

export const query = graphql`
  query ProductQuery($region: String, $slug: String) {
    allSanityProductPage(filter: { 
      region: { iban: { eq: $region } } 
      slug: { current: { eq: $slug } }
    }) {
      nodes {
        password
        title
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        components {
          ... on SanityAccordionBlock {
            ...sanityAccordionBlock
          }
          ... on SanityFullScreenContentBlock {
            ...sanityFullScreenContentBlock
          }
          ... on SanityImageContentCarouselBlock {
            ...sanityImageContentCarouselBlock
          }
          ... on SanityImagesContentBlock {
            ...sanityImagesContentBlock
          }
          ... on SanityInfoBlock {
            ...sanityInfoBlock
          }
          ... on SanityMediaContentBlock {
            ...sanityMediaContentBlock
          }
          ... on SanityStepsBlock {
            ...sanityStepsBlock
          }
        }
      }
    }
    altRegions: allSanityProductPage {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
